// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clients-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/clients.js" /* webpackChunkName: "component---src-pages-clients-js" */),
  "component---src-pages-contact-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookies-policy-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-index-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-advice-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/legal-advice.js" /* webpackChunkName: "component---src-pages-legal-advice-js" */),
  "component---src-pages-privacy-policy-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-pages-services-js": () => import("/home/fernando/Proyects/gatsby-site/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

